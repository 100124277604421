export const fr = {
  //* COMMONS
  COMMON_YES: "Oui",
  COMMON_NO: "Non",
  COMMON_REQUIRED_FIELD: "Champ obligatoire*",
  COMMON_EMPTY_MESSAGE: "Aucun résultat",
  COMMON_ADD: "Ajouter",
  COMMON_CHANGE: "Modifier",
  COMMON_BAD_VALUE_FIELD: "Champ erroné",
  COMMON_EXPORT: "Exporter",
  COMMON_IMPORT: "Parcourir",
  COMMON_SAVE: "Enregistrer",
  COMMON_CANCEL: "Annuler",
  COMMON_VALIDATE: "Valider",
  COMMON_DELETE: "Supprimer",
  COMMON_SEARCH: "Rechercher",
  COMMON_SEARCH_PLACEHOLDER: "Rechercher",
  COMMON_SEARCH_TITLE: "Rechercher",
  COMMON_PLACEHOLDER: "_",
  COMMON_ENABLED: "Actif",
  COMMON_DISABLED: "Inactif",
  COMMON_SAVE_SUCCESS: "Enregistrement réussi !",
  COMMON_DAY_DATE_FORMAT: `{year}-{month}-{day}`,
  COMMON_INVALID_EMAIL: "Format de l'adresse email invalide",
  COMMON_DATE_FORMAT: "YYYY-MM-dd",
  COMMON_TODAY: "Aujourd'hui",
  COMMON_YESTERDAY: "Hier",
  COMMON_FULLNAME: "{firstName} {lastName}",
  COMMON_INVITE: "Inviter",
  COMMON_ALL: "Tout",
  COMMON_CONFIRM: "Confirmer",
  COMMON_NOT_SAVED: "Des données modifiées seront perdues si vous quittez sans enregistrer, voulez vous continuer ?",

  //* Artwork
  ArtWorkDetailsPage_Country: "{value}",
  ArtworkAssociated_Title: "Vous aimerez aussi :",

  //* Informations Page
  InformationsPage_About: "À propos",
  InformationsPage_LegalNotice: "Mentions légales",
  InformationsPage_Agenda: "Voir tout l’agenda",

  //* About Page
  AboutPage_CGU: "CGU",
  AboutPage_Licences: "Licences",
  AboutPage_Agenda: "Voir tout l’agenda",

  //* DownloadApp Dialog
  DownloadApp_Dialog_helpText: "Pour une meilleure expérience, n’hésitez pas à télécharger notre application mobile.",
  AboutPage_Title: "À propos",
  AboutPage_Text1:
    "Implantée dans le quartier ancien du Pile à Roubaix, La Condition Publique est un Laboratoire Créatif permettant la rencontre entre les acteurs artistiques, culturels ou créatifs et les enjeux urbains, sociaux ou environnementaux.",
  AboutPage_Text2:
    "Terrain de jeu sans limite, tiers-lieu ancré dans son quartier, la Condition Publique envisage la créativité comme un levier pour l’innovation et la transition d’un territoire. Avec deux saisons culturelles par an, La Condition Publique développe une programmation riche et pluridisciplinaire, mêlant expositions temporaires, œuvres déployées hors-les-murs, concerts, spectacles, ateliers ouverts à tous au sein de son fablab, sa halle de construction et de sa communauté créative.",
  AboutPage_Text3:
    "Depuis 2004, la Condition Publique est un établissement public de coopération culturelle (EPCC) de la Région Hauts-de France, de la Métropole Européenne de Lille et de la Ville de Roubaix, soutenu au programme d’activité par le Département du Nord. Elle est labellisée Fabrique de Territoire par l’Etat (programme Nouveaux lieux, nouveaux liens).",
  AboutPage_Text4: "Infos / Programmation :\n",
  AboutPage_Text4_Link: "www.laconditionpublique.com",
  AboutPage_Text4_HttpLink: "https://www.laconditionpublique.com",

  //* Legal notice Page
  LegalNoticePage_CGU: "CGU",
  LegalNoticePage_Licences: "Licences",

  //* CGU
  CGUPage_Text_Intro:
    "Cette application est développée par la Condition Publique, Ideine et AirThink. Elle a été réalisée avec l’aide du Ministère de la Culture.",
  CGUPage_Text_Editeur_Title: "INFORMATION ÉDITEUR",
  CGUPage_Text_Editeur_Content_1: "L’application « La Condition Publique » est éditée par l’EPCC La Condition Publique",
  CGUPage_Text_Editeur_Content_2: "Adresse : 14 place Faidherbe - 59100 Roubaix Tél : +33 (0)3 28 33 48 33",
  CGUPage_Text_Real_Title: "RÉALISATION",
  CGUPage_Text_Real_Content_1: "Agence IDEINE https://www.ideine.fr/",
  CGUPage_Text_Real_Content_2: "V1 – Octobre 2021",
  CGUPage_Text_AR_Title: "REALITE AUGMENTEE",
  CGUPage_Text_AR_Content: "Agence Airthink www.airthink.fr",
  CGUPage_Text_Hosting_Title: "HÉBERGEMENT",
  CGUPage_Text_Hosting_Content: "OVH 2 rue Kellermann - 59100 Roubaix - France",
  CGUPage_Text_IntelProp_Title: "PROPRIÉTÉ INTELLECTUELLE",
  CGUPage_Text_IntelProp_Content:
    "Conformément au code de la Propriété Intellectuelle et plus généralement à tous les accords comportant des dispositions relatives au droit d'auteur, la reproduction partielle ou totale de textes, d'images ou d'illustrations non destinées explicitement à être téléchargées par les visiteurs, sont interdites sans autorisation préalable de l'éditeur ou de tout ayant-droit.",
  CGUPage_Text_Data_Title: "DONNÉES PERSONNELLES / DONNÉES ENTRÉES PAR L'UTILISATEUR ",
  CGUPage_Text_Data_Content:
    'Au cours de votre visite sur l’application, il pourra vous être demandé de saisir des informations personnelles nominatives vous concernant (nom, prénom, e-mail, etc.). Les données reçues de votre part sont reprises dans les fichiers de La Condition publique et servent uniquement à répondre à la demande d’informations que vous avez introduite, sous réserve des dispositions légales applicables et notamment de la Loi " Informatique et Libertés " modifiée par la loi du 6 août 2004. Conformément à l’article 39 de la Loi " Informatique et Libertés " n°78-17 du 6 janvier 1978, vous disposez d’un droit d’accès et de rectification des données vous concernant en écrivant à La Condition publique.',
  CGUPage_Text_Hyperlink_Title: "HYPERLIENS",
  CGUPage_Text_Hyperlink_Content:
    "L’application ‘La Condition Publique’ peut contenir des liens hypertextes menant à d'autres sites Internet totalement indépendants du site www.laconditionpublique.com. La Condition publique ne suppose aucunement, ni ne garantit que les informations contenues dans de tels liens hypertextes ou dans tout autre site Internet soient exactes, complètes ou véridiques. Dès lors, tout accès à un autre site Internet lié au site www.laconditionpublique.com s'effectue sous la propre responsabilité, pleine et entière, de l'utilisateur.",
  CGUPage_Text_Resp_Title: "RESPONSABILITÉS",
  CGUPage_Text_Resp_Content:
    "L'utilisation des informations contenues sur la présente application relève de la seule responsabilité de son utilisateur. Toutes les ressources contenues dans ce site, textes, visuels et illustrations (schémas, dessins, plans, photographies et animations informatiques) sont communiqués à titre purement informatif et ne peuvent en rien engager la responsabilité de l'éditeur. En effet, malgré le souhait de l'éditeur d’apporter les informations les plus exactes possibles et d’assurer une mise à jour régulière de l’application, celui–ci n’est pas responsable d’imprécisions, d’inexactitudes, d’erreurs ou de possibles omissions portant sur des informations contenues sur le site ni des résultats obtenus par l’utilisation et la pratique des informations délivrées sur ce support de communication.",
  CGUPage_Text_AccessRight_Title: "EXERCICE DU DROIT D'ACCÈS",
  CGUPage_Text_AccessRight_Content:
    'Conformément à l’article 34 de la loi "Informatique et Libertés", vous disposez d’un droit d’accès, de modification, de rectification et de suppression des données vous concernant. Pour exercer ce droit d’accès, adressez–vous à l’éditeur. Pour plus d’informations sur la loi « Informatique et Libertés », vous pouvez consulter le site Internet de la CNIL.',

  //* Home
  HomeInfosButton_Text: "Infos pratiques",

  SectionTitle_HighlightWord: "¤",
  SectionListPage_OtherExpo: "Autres expos",
};
