import { FormattedMessage, useIntl } from "react-intl";
import { Section } from "../../../Core/Models/Section/Section";
import { getNavigationService } from "../../../Core/Services/Interfaces/INavigationService";
import { Icons } from "../../Components/Views/Icons/Icons";
import { OTHER_EXHIBITION_ID } from "./SectionListPage";

export type SectionListProps = { items: Section[] };

export const SectionList = ({ items }: SectionListProps) => {
  const onClick = (item: Section) => () =>
    item.id === OTHER_EXHIBITION_ID
      ? getNavigationService().value().goToOtherSection()
      : getNavigationService().value().goToArtWorkList(item.id);
  return (
    <div className="SectionListPage-list">
      <div className="SectionListPage-mainElements">
        {items &&
          items.map((item, index) => (
            <div className="SectionListPage-element" key={index} onClick={onClick(item)}>
              <Title name={item.name} />
              <img src={`${item.image_url}?height=120`} alt={item.name} />
            </div>
          ))}

        {/* Empty items to prevent layout shift on load */}
        {!items ||
          (items.length === 0 && (
            <>
              <div className="SectionListPage-element"></div>
              <div className="SectionListPage-element"></div>
              <div className="SectionListPage-element"></div>
            </>
          ))}
      </div>
      <div
        className="SectionListPage-elementInfos"
        key={3}
        onClick={() => getNavigationService().value().goToInformations()}>
        {Icons.Info}
        <div className="SectionListPage-elementInfosTitle">
          <FormattedMessage id="HomeInfosButton_Text" />
        </div>
      </div>
    </div>
  );
};

export const OtherSectionList = ({ items }: SectionListProps) => {
  if (!items) {
    return null;
  }

  return (
    <div className="SectionListPage-list">
      {items.map((item, index) => (
        <div
          className="SectionListPage-others-element"
          key={index}
          onClick={() => getNavigationService().value().goToArtWorkList(item.id)}>
          <img src={`${item.image_url}?height=120`} alt={item.name} />

          <Title name={item.name} />
        </div>
      ))}
    </div>
  );
};

const Title = ({ name }: { name: string }) => {
  const i18n = useIntl();
  var highlightSeparator = i18n.formatMessage({ id: "SectionTitle_HighlightWord" });
  var from = name.indexOf(highlightSeparator);
  var to = from + highlightSeparator.length;
  if (from >= 0 && to >= 0) {
    const highlight = name.slice(0, from);
    const sub = name.slice(to);

    return (
      <span className="SectionListPage-elementTitle">
        <span className="highlight">{highlight}</span>
        {sub}
      </span>
    );
  }

  return <span className="SectionListPage-elementTitle">{name}</span>;
};
