import "./CGUPage.scss";
import { FormattedMessage } from "react-intl";
import { getNavigationService } from "../../../../../Core/Services/Interfaces/INavigationService";
import { Header } from "../../../../Components/Views/Header/Header";

const CGUPage = () => {
  return (
    <Header backAction={getNavigationService().value().goBack}>
      <div className="CGUPage-root">
        <h1 className="CGUPage-title">
          <FormattedMessage id="LegalNoticePage_CGU" />
        </h1>

        <p>
          <FormattedMessage id="CGUPage_Text_Intro" />
        </p>

        <h2>
          <FormattedMessage id="CGUPage_Text_Editeur_Title" />
        </h2>
        <p>
          <FormattedMessage id="CGUPage_Text_Editeur_Content_1" />
        </p>
        <p>
          <FormattedMessage id="CGUPage_Text_Editeur_Content_2" />
        </p>

        <h2>
          <FormattedMessage id="CGUPage_Text_Real_Title" />
        </h2>
        <p>
          <FormattedMessage id="CGUPage_Text_Real_Content_1" />
        </p>
        <p>
          <FormattedMessage id="CGUPage_Text_Real_Content_2" />
        </p>

        <h2>
          <FormattedMessage id="CGUPage_Text_AR_Title" />
        </h2>
        <p>
          <FormattedMessage id="CGUPage_Text_AR_Content" />
        </p>

        <h2>
          <FormattedMessage id="CGUPage_Text_Hosting_Title" />
        </h2>
        <p>
          <FormattedMessage id="CGUPage_Text_Hosting_Content" />
        </p>

        <h2>
          <FormattedMessage id="CGUPage_Text_IntelProp_Title" />
        </h2>
        <p>
          <FormattedMessage id="CGUPage_Text_IntelProp_Content" />
        </p>

        <h2>
          <FormattedMessage id="CGUPage_Text_Data_Title" />
        </h2>
        <p>
          <FormattedMessage id="CGUPage_Text_Data_Content" />
        </p>

        <h2>
          <FormattedMessage id="CGUPage_Text_Hyperlink_Title" />
        </h2>
        <p>
          <FormattedMessage id="CGUPage_Text_Hyperlink_Content" />
        </p>

        <h2>
          <FormattedMessage id="CGUPage_Text_Resp_Title" />
        </h2>
        <p>
          <FormattedMessage id="CGUPage_Text_Resp_Content" />
        </p>

        <h2>
          <FormattedMessage id="CGUPage_Text_AccessRight_Title" />
        </h2>
        <p>
          <FormattedMessage id="CGUPage_Text_AccessRight_Content" />
        </p>
      </div>
    </Header>
  );
};

export { CGUPage };
