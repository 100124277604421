import { useState } from "react";
import { getNavigationService } from "../../../Core/Services/Interfaces/INavigationService";
import NoImage from "../../assets/images/img_no_image.png";
import { ArtWork } from "../../../Core/Models/ArtWork/ArtWork";
import "./ArtWorkListPage.scss";

export type ArtWorkProps = {
  item: ArtWork;
  sectionId: string;
};

export type ArtWorkImageProp = {
  image_url: string;
};

const ArtWorkListElement = ({ item, sectionId }: ArtWorkProps) => (
  <div
    className="ArtWorkList-element"
    onClick={() => getNavigationService().value().goToArtWorkDetails(sectionId, item.id)}>
    <ArtWorkListElementImage image_url={item.image_url} />

    <div className="ArtWorkList-elementDetails">
      <div className="ArtWorkList-elementTitle">{item.title}</div>
      <div className="ArtWorkList-elementAuthor">{item.artist_name}</div>
      <div className="ArtWorkList-elementDate">{item.year}</div>
    </div>
  </div>
);

export { ArtWorkListElement };

const ArtWorkListElementImage = ({ image_url }: ArtWorkImageProp) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div className="ArtWorkList-elementImage">
      {image_url && (
        <img
          src={image_url}
          alt=""
          className="ArtWorkList-urlImage"
          onLoad={() => {
            setIsLoaded(true);
          }}
          style={isLoaded ? { opacity: 1 } : { opacity: 0 }}
        />
      )}
      <img src={NoImage} alt="artwork" className="ArtWorkList-fallbackImage" />
    </div>
  );
};
