import "./Map.scss";

import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { Constants } from "../../../../Core/Constants";
import { ArtWorkMap } from "../../../../Core/Models/ArtWork/ArtWork";
import IcMarker3dNew from "../../../assets/icons/ic_artwork_marker_3d_new.svg";
import IcMarker3d from "../../../assets/icons/ic_artwork_marker_3d.svg";
import IcMarkerNew from "../../../assets/icons/ic_artwork_marker_new.svg";
import IcMarker from "../../../assets/icons/ic_artwork_marker.svg";
import IcMarkerCP from "../../../assets/icons/ic_marker_cp.png";
import IcMarkerCurrent from "../../../assets/icons/ic_marker_current.svg";

export interface MapProps {
  markers: ArtWorkMap[];
  onClick: (artWorkId: string) => void;
  currentPosition?: GeolocationPosition;
}

const Map = ({ markers, onClick, currentPosition }: MapProps) => {
  const getIcon = (marker: ArtWorkMap): string => {
    if (marker?.has_ar_file) {
      if (marker?.is_new) {
        return IcMarker3dNew;
      } else {
        return IcMarker3d;
      }
    } else {
      if (marker?.is_new) {
        return IcMarkerNew;
      } else {
        return IcMarker;
      }
    }
  };

  const getBounds = (markers: ArtWorkMap[]): { maxLat: number; maxLng: number; minLat: number; minLng: number } => {
    let maxLat = 0;
    let maxLng = 0;
    let minLat = 90;
    let minLng = 180;

    markers.forEach((marker) => {
      if (marker.latitude > maxLat) {
        maxLat = marker.latitude;
      } else if (marker.latitude < minLat) {
        minLat = marker.latitude;
      }

      if (marker.longitude > maxLng) {
        maxLng = marker.longitude;
      } else if (marker.longitude < minLng) {
        minLng = marker.longitude;
      }
    });
    return { maxLat, maxLng, minLat, minLng };
  };

  return (
    <LoadScript googleMapsApiKey={Constants.getGoogleMapKey()}>
      <GoogleMap
        mapContainerClassName="map-container"
        onLoad={(map) => {
          const bounds = getBounds(markers);
          const mapBounds = new window.google.maps.LatLngBounds(
            { lat: bounds.minLat, lng: bounds.minLng },
            { lat: bounds.maxLat, lng: bounds.maxLng }
          );
          map.fitBounds(mapBounds, { bottom: 1, left: 1, right: 1, top: 1 });
        }}
        options={{
          disableDefaultUI: true,
          styles: [{ elementType: "labels", featureType: "poi", stylers: [{ visibility: "off" }] }],
        }}>
        {markers.map((marker) => {
          return (
            //@ts-ignore
            <Marker
              position={{ lat: marker.latitude, lng: marker.longitude }}
              icon={getIcon(marker)}
              onClick={() => onClick(marker.id)}
            />
          );
        })}
        {currentPosition && (
          <Marker
            position={{ lat: currentPosition.coords.latitude, lng: currentPosition.coords.longitude }}
            icon={IcMarkerCurrent}
          />
        )}
        <Marker position={{ lat: 50.691056, lng: 3.190679 }} icon={IcMarkerCP} />
      </GoogleMap>
    </LoadScript>
  );
};

export { Map };
