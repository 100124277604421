import { IconButton } from "@material-ui/core";
import { getNavigationService } from "../../../../Core/Services/Interfaces/INavigationService";
import { Header } from "../../../Components/Views/Header/Header";
import { Icons } from "../../../Components/Views/Icons/Icons";
import "./ArtWorkDetailsPage.scss";
import { useArtWorkDetailsPageController } from "./ArtWorkDetailsPageController";
import NoImage from "../../../assets/images/img_artwork_no_image.png";
import { FormattedMessage } from "react-intl";
import { ArtworkAssociated } from "./ArtWorkAssociated/ArtWorkAssociated";

const ArtWorkDetailsPage = () => {
  const { state, vm } = useArtWorkDetailsPageController();

  return (
    <Header backAction={() => getNavigationService().value().goBack()}>
      <div className="ArtWorkDetailsPage-root">
        <div className="ArtWorkDetailsPage-picture">
          <img src={state.item.picture_url ? state.item.picture_url : NoImage} alt="artwork" />
        </div>
        <div className="ArtWorkDetailsPage-infos">
          <div className="ArtWorkDetailsPage-actions">
            <IconButton onClick={vm.onShare}>{Icons.Share}</IconButton>
          </div>
          <div className="ArtWorkDetailsPage-title">{state.item.title}</div>
          <div className="ArtWorkDetailsPage-artist">{state.item.artist_name}</div>
          <div className="ArtWorkDetailsPage-subtitle">
            {state.item.subtitle} {state.item.subtitle && state.item.year ? "-" : ""} {state.item.year}
          </div>
          {state.item.country && (
            <div className="ArtWorkDetailsPage-country">
              <FormattedMessage id="ArtWorkDetailsPage_Country" values={{ value: state.item.country }} />
            </div>
          )}
          <div className="ArtWorkDetailsPage-shortDescription">{state.item.short_description}</div>
          <div className="ArtWorkDetailsPage-border" />
          <div
            className="ArtWorkDetailsPage-description"
            dangerouslySetInnerHTML={{ __html: state.item.description }}
          />
          <div className="ArtWorkDetailsPage-copyrights">{state.item.copyrights}</div>
        </div>
      </div>
      <ArtworkAssociated items={state.item.associated_artworks} />
    </Header>
  );
};

export { ArtWorkDetailsPage };
