import { FormattedMessage } from "react-intl";
import { getNavigationService } from "../../../../../Core/Services/Interfaces/INavigationService";
import { Header } from "../../../../Components/Views/Header/Header";
import { licences } from "../../../../../licences";
import "./LicencesPage.scss";

const LicencesPage = () => {
  return (
    <Header backAction={getNavigationService().value().goBack}>
      <div className="LicencesPage-root">
        <div className="LicencesPage-title">
          <FormattedMessage id="LegalNoticePage_Licences" />
        </div>
        {licences.map((item) => {
          return (
            <div className="LicencesPage-licenceRoot">
              <div className="LicencesPage-licenceName">{item.Name}</div>
              <div className="LicencesPage-licenceAuthor">{item.Authors}</div>
              <p>{item.Licences}</p>
            </div>
          );
        })}
      </div>
    </Header>
  );
};

export { LicencesPage };
