import { FormattedMessage } from "react-intl";
import { getNavigationService } from "../../../Core/Services/Interfaces/INavigationService";
import { Header } from "../../Components/Views/Header/Header";
import { useArtWorkListPageController } from "./ArtWorkListPageController";
import "./ArtWorkListPage.scss";
import { ArtWorkListElement } from "./ArtWorkListElement";
import { CustomSearch } from "../../Components/Views/SearchInput/CustomSearch";
import Map from "../../assets/images/img_map.svg";

export interface ArtWorkListPageProps {}

const ArtWorkListPage = () => {
  const { vm, state, i18n, sectionId } = useArtWorkListPageController();

  return (
    <Header
      backAction={() => getNavigationService().value().goBack()}
      renderCustomAction={
        sectionId === "9304c0b4-fef0-49af-91a4-4092ced9647a" && (
          <img src={Map} alt="map" className="ArtWorkListPage-action-map" />
        )
      }
      customAction={() => getNavigationService().value().goToArtWorkMap(sectionId)}>
      <div className="ArtWorkListPage-root">
        {/* <div className="ArtWorkListPage-title">{state.section?.name ?? state.sectionName}</div> */}
        {state.section?.image_url && (
          <img src={state.section?.image_url} alt="" className="ArtWorkListPage-header-image" />
        )}
        {state.section?.description && (
          <div className="ArtWorkListPage-header-description">{state.section?.description}</div>
        )}
        <div className="ArtWorkListPage-actions">
          <CustomSearch
            inputValue={state.search}
            placeholder={i18n.formatMessage({ id: "COMMON_SEARCH" })}
            onChange={(value) => vm.onChangeField("search", value)}
            onSubmit={vm.onSearch}
          />
        </div>
        <div className="ArtWorkList-list">
          {state.items.map((item) => (
            <ArtWorkListElement key={item.id} item={item} sectionId={sectionId} />
          ))}
        </div>
      </div>
    </Header>
  );
};

export { ArtWorkListPage };
