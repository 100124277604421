import { useEffect, useState } from "react";
import { CustomDialog } from "../../Components/Views/CustomDialogs/CustomDialog/CustomDialog";
import Cookies from "js-cookie";
import "./NewsDialog.scss";
import { News } from "../../../Core/Models/News";
import { IoC } from "../../../Core/Services/ServicesContainer";
import { INewsDataService, NewsDataServiceName } from "../../../Core/DataServices/NewsDataService";
import { XHRRequestCanceler } from "../../../Libs/xhr/XHRRequestCanceler";

const useNewsDialog = () => {
  const [news, setNews] = useState<News>();
  useEffect(() => {
    const canceler = new XHRRequestCanceler();

    (async () => {
      try {
        const nResponse = await IoC.get<INewsDataService>(NewsDataServiceName).getNews(canceler);
        if (nResponse.is_success) {
          const cookie = Cookies.get("news");
          if (!cookie?.includes(nResponse.data.id)) {
            if (!canceler.isCanceled) {
              setNews(nResponse.data);
            }
          }
        }
      } catch {
        // do nothing
      }
    })();

    return () => {
      canceler.cancel();
    };
  }, []);

  const markAsRead = (n: News) => {
    Cookies.set("news", n.id);
    setNews(undefined);
  };

  return { news, markAsRead };
};

export const NewsDialog = () => {
  const { news, markAsRead } = useNewsDialog();
  const onClose = () => {
    news && markAsRead(news);
  };

  return (
    <CustomDialog
      open={Boolean(news)}
      onClose={onClose}
      renderActions={() => <></>}
      fullscreen
      classes={{ paper: "NewsDialog-root" }}>
      {news && (
        <div className="NewsDialog-dialogContent-root">
          <h3>{news.title}</h3>
          <img src={news.picture_url} alt="" />
          <span className="description">{news.content}</span>
        </div>
      )}
    </CustomDialog>
  );
};
