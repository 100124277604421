import { history } from "../../Configs/history/history";
import { AppRoutes } from "../../Core/App/AppRoutes";
import { INavigationService } from "../../Core/Services/Interfaces/INavigationService";

//* Generic detail route
export const getDetailsRoute = (id: string, detailsRoute: string): string => `${detailsRoute}`.replace(":id", id);

export const getSectionDetailsRoute = (sectionId: string, detailsRoute: string): string =>
  `${detailsRoute}`.replace(":sectionId", sectionId);

export const getArtWorkDetailsRoute = (sectionId: string, artworkId: string, detailsRoute: string): string =>
  `${detailsRoute}`.replace(":sectionId", sectionId).replace(":artworkId", artworkId);

export class NavigationService implements INavigationService {
  public getHomeLink = AppRoutes.DEFAULT;

  public goTo = (route: string, replace?: boolean): void => {
    if (replace) {
      history.replace(route);
    } else {
      history.push(route);
    }
  };

  public goBack = (): void => history.goBack();
  public goToHome = (): void => history.push(this.getHomeLink);
  public goToLogin = (): void => history.push(AppRoutes.LOGIN);
  public goToArtWorkList = (sectionId: string): void =>
    history.push(getSectionDetailsRoute(sectionId, AppRoutes.ART_WORK_LIST));
  public goToArtWorkFilter = (sectionId: string): void =>
    history.push(getSectionDetailsRoute(sectionId, AppRoutes.ART_WORK_FILTER));
  public goToArtWorkDetails = (sectionId: string, artworkId: string): void =>
    history.push(getArtWorkDetailsRoute(sectionId, artworkId, AppRoutes.ART_WORK_DETAILS));
  public goToArtWorkMap = (sectionId: string): void =>
    history.push(getSectionDetailsRoute(sectionId, AppRoutes.ART_WORK_MAP));
  public goToInformations = (): void => history.push(AppRoutes.INFORMATIONS);
  public goToAbout = (): void => history.push(AppRoutes.ABOUT);
  public goToLegalNotice = (): void => history.push(AppRoutes.LEGAL_NOTICE);
  public goToCGU = (): void => history.push(AppRoutes.CGU);
  public goToLicences = (): void => history.push(AppRoutes.LICENCES);
  public goToOtherSection = () => history.push(AppRoutes.SECTION_OTHERS);
}
