import "./AboutPage.scss";
import { Header } from "../../../Components/Views/Header/Header";
import { getNavigationService } from "../../../../Core/Services/Interfaces/INavigationService";
import { FormattedMessage, useIntl } from "react-intl";
import AboutIlllustration from "../../../assets/images/img_about.jpg";

const AboutPage = () => {
  const i18n = useIntl();

  return (
    <Header backAction={getNavigationService().value().goBack}>
      <div className="AboutPage-root">
        <h1 className="AboutPage-title">
          <FormattedMessage id="AboutPage_Title" />
        </h1>
        <img className="AboutPage-illustration" src={AboutIlllustration} alt="About" />
        <p className="AboutPage-text">
          <FormattedMessage id="AboutPage_Text1" />
        </p>
        <p className="AboutPage-text">
          <FormattedMessage id="AboutPage_Text2" />
        </p>
        <p className="AboutPage-text">
          <FormattedMessage id="AboutPage_Text3" />
        </p>
        <p className="AboutPage-text">
          <FormattedMessage id="AboutPage_Text4" />
          <a href={i18n.formatMessage({ id: "AboutPage_Text4_HttpLink" })}>
            <FormattedMessage id="AboutPage_Text4_Link" />
          </a>
        </p>
      </div>
    </Header>
  );
};

export { AboutPage };
