export enum AppRoutes {
  HOME = "/",
  DEFAULT = "/",
  LOGIN = "/login",
  SECTION = "/sections",
  SECTION_OTHERS = "/sections/others",
  ART_WORK_LIST = "/section/:sectionId/artwork",
  ART_WORK_FILTER = "/section/:sectionId/artwork/filter",
  ART_WORK_MAP = "/section/:sectionId/artwork/map",
  ART_WORK_DETAILS = "/section/:sectionId/artwork/:artworkId",
  INFORMATIONS = "/informations",
  ABOUT = "/informations/about",
  LEGAL_NOTICE = "/informations/legal",
  CGU = "/informations/legal/cgu",
  LICENCES = "/informations/legal/licences",
}
