import { XHRRequestCanceler } from "../../Libs/xhr/XHRRequestCanceler";
import { ApiUrls } from "../Models/ApiUrls";
import { ArtWorkDetailsResponse, ArtWorkListResponse, ArtWorkMapListResponse } from "../Models/ArtWork/ArtWork";
import { BaseDataService } from "./Base/BaseDataService";

export const ArtWorkDataServiceName: string = "ArtWorksDataService";

export interface IArtWorkDataService {
  getArtWorks: (sectionId: string, cts: XHRRequestCanceler, search?: string) => Promise<ArtWorkListResponse>;

  getArtWork: (artworkId: string, cts: XHRRequestCanceler) => Promise<ArtWorkDetailsResponse>;

  getArtWorksMap: (cts: XHRRequestCanceler) => Promise<ArtWorkMapListResponse>;
}

export class ArtWorkDataService extends BaseDataService implements IArtWorkDataService {
  public getArtWorks(sectionId: string, cts: XHRRequestCanceler, search?: string): Promise<ArtWorkListResponse> {
    const params = {
      sectionId: sectionId,
      search: search,
    };

    return this.get<ArtWorkListResponse>(ApiUrls.ART_WORKS, cts, { params }, true);
  }

  public getArtWork(artworkId: string, cts: XHRRequestCanceler): Promise<ArtWorkDetailsResponse> {
    return this.get<ArtWorkDetailsResponse>(ApiUrls.ART_WORK(artworkId), cts, {}, true);
  }

  public getArtWorksMap(cts: XHRRequestCanceler): Promise<ArtWorkMapListResponse> {
    return this.get<ArtWorkMapListResponse>(ApiUrls.ART_WORK_MAP, cts, {}, true);
  }
}
