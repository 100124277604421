import { XHRRequestCanceler } from "../../../Libs/xhr/XHRRequestCanceler";
import { ArtWorkDataServiceName, IArtWorkDataService } from "../../DataServices/ArtWorkDataService";
import { ArtWorkMap, ArtWorkMapListResponse } from "../../Models/ArtWork/ArtWork";
import { IoC } from "../../Services/ServicesContainer";
import { BaseAppViewModel } from "../Base/BaseAppViewModel";
import { BaseViewState } from "../Base/BaseViewModel";

export interface ArtWorkMapViewState extends BaseViewState {
  items: ArtWorkMap[];
  currentPosition?: GeolocationPosition;
}

const InitialArtWorkMapViewState: ArtWorkMapViewState = {
  items: [],
  currentPosition: undefined,
};

export class ArtWorkMapViewModel extends BaseAppViewModel<ArtWorkMapViewState> {
  private readonly ArtWorkDataService: IArtWorkDataService;

  constructor() {
    super(InitialArtWorkMapViewState);
    this.ArtWorkDataService = IoC.get<IArtWorkDataService>(ArtWorkDataServiceName);
    this.getItems();
  }

  protected getItems = () => {
    this.WrapError(
      async (cts: XHRRequestCanceler) => {
        const response: ArtWorkMapListResponse = await this.ArtWorkDataService.getArtWorksMap(cts);
        navigator.geolocation.getCurrentPosition((position) => {
          this.setState({ ...this.state, currentPosition: position });
        });
        this.setState({ ...this.state, items: response.data });
      },
      { withBusy: true }
    );
  };
}
