import "./LegalNoticePage.scss";
import { Header } from "../../../Components/Views/Header/Header";
import { ButtonBase } from "@material-ui/core";
import { getNavigationService } from "../../../../Core/Services/Interfaces/INavigationService";
import { FormattedMessage } from "react-intl";

const LegalNoticePage = () => {
  return (
    <Header backAction={getNavigationService().value().goBack}>
      <div className="LegalNoticePage-root">
        <ButtonBase className="LegalNoticePage-button" onClick={getNavigationService().value().goToCGU}>
          <FormattedMessage id="LegalNoticePage_CGU" />
        </ButtonBase>
        <ButtonBase className="LegalNoticePage-button" onClick={getNavigationService().value().goToLicences}>
          <FormattedMessage id="LegalNoticePage_Licences" />
        </ButtonBase>
      </div>
    </Header>
  );
};

export { LegalNoticePage };
