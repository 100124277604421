import "./Header.scss";
import Logo from "../../../assets/images/img_condition_publique_logo_small.png";
import { ButtonBase, IconButton } from "@material-ui/core";
import { Icons } from "../Icons/Icons";
import { getNavigationService } from "../../../../Core/Services/Interfaces/INavigationService";

export interface HeaderProps {
  title?: React.ReactNode;
  children: React.ReactNode;
  backAction?: () => void;
  closeAction?: () => void;
  renderCustomAction?: React.ReactNode;
  customAction?: () => void;
}

const Header = ({ title, children, backAction, closeAction, renderCustomAction, customAction }: HeaderProps) => {
  return (
    <div className="Header-root">
      <div className="Header-header">
        {backAction ? (
          <IconButton onClick={backAction} className="Header-back">
            {Icons.Left}
          </IconButton>
        ) : (
          <div className="Header-back" />
        )}
        <ButtonBase onClick={getNavigationService().value().goToHome}>
          {title ? title : <img src={Logo} alt="logo" />}
        </ButtonBase>
        {closeAction ? (
          <IconButton onClick={closeAction} className="Header-close">
            {Icons.CloseWhite}
          </IconButton>
        ) : renderCustomAction ? (
          <IconButton onClick={customAction} className="Header-close">
            {renderCustomAction}
          </IconButton>
        ) : (
          <div className="Header-close" />
        )}
      </div>
      <div className="Header-children">{children}</div>
    </div>
  );
};

export { Header };
