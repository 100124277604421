import { XHRRequestCanceler } from "../../../Libs/xhr/XHRRequestCanceler";
import { IArtWorkDataService, ArtWorkDataServiceName } from "../../DataServices/ArtWorkDataService";
import { ArtWorkDetails } from "../../Models/ArtWork/ArtWork";
import { IoC } from "../../Services/ServicesContainer";
import { BaseAppViewModel } from "../Base/BaseAppViewModel";
import { BaseViewState } from "../Base/BaseViewModel";

export interface ArtWorkDetailsViewState extends BaseViewState {
  sectionName: string;
  search: string;
  item: ArtWorkDetails;
}

const InitialArtWorkDetailsViewState: ArtWorkDetailsViewState = {
  sectionName: "",
  search: "",
  item: {
    app_url: "",
    ar_file_id: "",
    ar_file_url: "",
    artist_name: "",
    country: "",
    description: "",
    id: "",
    is_outside: false,
    latitude: 0,
    longitude: 0,
    picture_url: "",
    section: {
      id: "",
      image_url: "",
      is_mapped: false,
      name: "",
      is_main_section: false,
      is_secondary_section: false,
      description: "",
    },
    subtitle: "",
    title: "",
    year: "",
    short_description: "",
    copyrights: "",
    associated_artworks: [],
  },
};

export class ArtWorkDetailsViewModel extends BaseAppViewModel<ArtWorkDetailsViewState> {
  private readonly ArtWorkDataService: IArtWorkDataService;
  public artworkId: string;

  constructor(artworkId: string) {
    super(InitialArtWorkDetailsViewState);
    this.ArtWorkDataService = IoC.get<IArtWorkDataService>(ArtWorkDataServiceName);
    this.artworkId = artworkId;
    this.getItems();
  }

  public onArtworkIdChanged = (artworkId: string) => {
    this.artworkId = artworkId;
    this.getItems();
  };

  protected getItems = () => {
    this.WrapError(async (cts: XHRRequestCanceler) => {
      const response = await this.ArtWorkDataService.getArtWork(this.artworkId, cts);
      const toDescriptionRTE = (desc: string = "") => {
        desc.replaceAll("&amp;", "&");

        const regex = /<video[^>]+><\/video>/g;
        const regex2 = /"([^"]*)"/g;

        let matches;
        let matches2;

        while ((matches = regex.exec(desc)) !== null) {
          const videoTag = matches[0];

          while ((matches2 = regex2.exec(videoTag)) !== null) {
            const videoSrc = matches2[1].replaceAll("&amp;", "&");
            desc = desc.replace(videoTag, `<video src="${videoSrc}" type={video/*} controls></video>`);
          }
        }
        return desc;
      };

      const newState = {
        ...this.state,
        item: { ...response.data, description: toDescriptionRTE(response.data.description) },
      };

      this.setState(newState);
    });
  };

  public onChangeField = (field: keyof ArtWorkDetailsViewState, value: any) => {
    this.setState({ ...this.state, [field]: value });
  };

  public onShare = () => {
    navigator.clipboard.writeText(this.state.item.app_url);
  };
}
