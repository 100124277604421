import React, { HTMLAttributes } from "react";
import icInfo from "../../../assets/icons/ic_info.svg";
import icLeft from "../../../assets/icons/ic_arrow_left.svg";
import icSearch from "../../../assets/icons/ic_search.svg";
import icClose from "../../../assets/icons/ic_close.svg";
import icCloseWhite from "../../../assets/icons/ic_close_white.svg";
import icStar from "../../../assets/icons/ic_star.svg";
import icArrowRight from "../../../assets/icons/ic_arrow_right.svg";
import icStarBlueOutlined from "../../../assets/icons/ic_star_blue_outlined.svg";
import icStarBlueFilled from "../../../assets/icons/ic_star_blue_filled.svg";
import icShare from "../../../assets/icons/ic_share.png";
import ic3d from "../../../assets/icons/ic_3d.svg";
import icMarker3dNew from "../../../assets/icons/ic_artwork_marker_3d_new.svg";
import icMarkerNew from "../../../assets/icons/ic_artwork_marker_new.svg";
import icMarker3d from "../../../assets/icons/ic_artwork_marker_3d.svg";
import icMarker from "../../../assets/icons/ic_artwork_marker.svg";
import icSearchWhite from "../../../assets/icons/ic_search_white.svg";

export interface IconProps {
  src: string;
  name?: string;
  className?: string;
  inlineStyle?: HTMLAttributes<HTMLImageElement>;
}

export const Icon = ({ src, name, className, inlineStyle }: IconProps): React.ReactElement => {
  return <img className={className} src={src} alt={name} style={inlineStyle} />;
};

export class Icons {
  public static Info = Icon({
    src: icInfo,
  });

  public static Left = Icon({
    src: icLeft,
  });

  public static Search = Icon({
    src: icSearch,
  });

  public static Close = Icon({
    src: icClose,
  });

  public static CloseWhite = Icon({
    src: icCloseWhite,
  });

  public static Star = Icon({
    src: icStar,
  });

  public static ArrowRight = Icon({
    src: icArrowRight,
  });

  public static StarBlueOutlined = Icon({
    src: icStarBlueOutlined,
  });

  public static StarBlueFilled = Icon({
    src: icStarBlueFilled,
  });

  public static Share = Icon({
    src: icShare,
    inlineStyle: { height: "1em" } as any,
  });

  public static Ar = Icon({
    src: ic3d,
  });

  public static Marker = Icon({
    src: icMarker,
  });

  public static Marker3d = Icon({
    src: icMarker3d,
  });

  public static Marker3dNew = Icon({
    src: icMarker3dNew,
  });

  public static MarkerNew = Icon({
    src: icMarkerNew,
  });

  public static SearchWhite = Icon({
    src: icSearchWhite,
  });
}

export default Icon;
