import { fr } from "../../Core/Locales/fr";
import { SUPPORTED_LOCALES } from "../../Core/Services/I18n/SetupIntl";
import { LocaleMessageState } from "../../Core/Services/I18n/typings/LocaleMessageState";

const I18n: LocaleMessageState = {
  defaultLocale: "fr",
  locale: "fr",
  supported_locales: SUPPORTED_LOCALES,
  messages: { ...fr },
};

export const InitialState = { I18n };
