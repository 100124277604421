import "./InformationsPage.scss";
import { Header } from "../../Components/Views/Header/Header";
import { ButtonBase } from "@material-ui/core";
import { getNavigationService } from "../../../Core/Services/Interfaces/INavigationService";
import { FormattedMessage } from "react-intl";

const InformationsPage = () => {
  return (
    <Header backAction={getNavigationService().value().goBack}>
      <div className="InformationsPage-root">
        <div className="InformationsPage-mainButtons">
          <ButtonBase className="InformationsPage-button" onClick={getNavigationService().value().goToAbout}>
            <FormattedMessage id="InformationsPage_About" />
          </ButtonBase>
          <ButtonBase className="InformationsPage-button" onClick={getNavigationService().value().goToLegalNotice}>
            <FormattedMessage id="InformationsPage_LegalNotice" />
          </ButtonBase>
        </div>
        <ButtonBase className="InformationsPage-agenda" href="https://laconditionpublique.com/agenda">
          <FormattedMessage id="InformationsPage_Agenda" />
        </ButtonBase>
      </div>
    </Header>
  );
};

export { InformationsPage };
