import { Theme } from "@material-ui/core";
import React, { Suspense } from "react";
import { DialogContainer } from "../DialogContainer/DialogContainer";
import { LoadingPage } from "../LoadingPage/LoadingPage";
import { MuiApp } from "../MuiApp/MuiApp";
import { ReduxApp } from "../ReduxApp/ReduxApp";
import { CustomToastContainer } from "./../CustomToastContainer/CustomToastContainer";
import { IntlWrapper } from "./IntlWrapper/IntlWrapper";
import { MountActionsGate } from "./MountActionsGate/MountActionsGate";

export interface AppWrapperProps {
  store: any;
  persistor: any;
  children?: React.ReactNode;

  //* Array of Promises to execute on mount
  mountActions: Array<Promise<boolean>>;
  //* Automatically setup react-intl & intl
  //* This does NOT setup the messages, only the locales & 'intl', if it needs to be imported
  setupIntl: boolean;
  //* If should trigger 'BaseActions.triggerEssentialReload'
  withEssentialReload: boolean;

  getMuiTheme: () => Theme;
}

export const AppWrapper = ({
  children,
  store,
  persistor,
  mountActions,
  setupIntl,
  withEssentialReload,
  getMuiTheme,
}: AppWrapperProps) => {
  return (
    <MuiApp getMuiTheme={getMuiTheme}>
      <Suspense fallback={<LoadingPage />}>
        <ReduxApp store={store} persistor={persistor}>
          <IntlWrapper>
            <MountActionsGate
              actions={mountActions as any}
              setupIntl={setupIntl}
              withEssentialReload={withEssentialReload}>
              <>
                {children}
                <CustomToastContainer />
                <DialogContainer />
              </>
            </MountActionsGate>
          </IntlWrapper>
        </ReduxApp>
      </Suspense>
    </MuiApp>
  );
};
