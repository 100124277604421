import "./ArtWorkAssociated.scss";
import { FormattedMessage } from "react-intl";
import { ArtWorkListElement } from "../../ArtWorkListElement";
import { ArtWork } from "../../../../../Core/Models/ArtWork/ArtWork";

type ArtworkAssociatedProps = {
  items: Array<ArtWork>;
};

export const ArtworkAssociated = ({ items }: ArtworkAssociatedProps) => {
  if (items.length <= 0) {
    return null;
  }

  return (
    <div className="ArtWorkAssociated-root">
      <div className="ArtWorkAssociated-title">
        <FormattedMessage id={"ArtworkAssociated_Title"} />
      </div>

      <div className="ArtWorkList-list">
        {items.map((item) => (
          <ArtWorkListElement key={item.id} item={item} sectionId={item.section.id} />
        ))}
      </div>
    </div>
  );
};
