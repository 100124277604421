import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useRouteMatch } from "react-router-dom";
import { AppRoutes } from "../../../Core/App/AppRoutes";
import { Section } from "../../../Core/Models/Section/Section";
import { getNavigationService } from "../../../Core/Services/Interfaces/INavigationService";
import { SectionListViewState } from "../../../Core/ViewModels/Section/SectionListViewModel";
import ImgConditionPublique from "../../assets/images/img_condition_publique_logo.png";
import DownloadAndroid from "../../assets/images/img_download_android.png";
import DownloadIos from "../../assets/images/img_download_ios.png";
import { CustomDialog } from "../../Components/Views/CustomDialogs/CustomDialog/CustomDialog";
import { Header } from "../../Components/Views/Header/Header";
import { CustomSearch } from "../../Components/Views/SearchInput/CustomSearch";
import { NewsDialog } from "../News/NewsDialog";
import { OtherSectionList, SectionList } from "./SectionList";
import "./SectionListPage.scss";
import { useSectionListPageController } from "./SectionListPageController";
import imgOtherExpo from "../../assets/images/img_others_expo.jpg";

export interface SectionListPageProps {}

// sections that need to be next to the main section.
const SPECIAL_SECTIONS_NAMES = ["Parcours d'Art"];
export const OTHER_EXHIBITION_ID = "OTHER";

const OTHER_EXHIBITION: Section = {
  id: OTHER_EXHIBITION_ID,
  name: "Autres¤Expositions",
  description: "",
  image_url: imgOtherExpo,
  is_main_section: false,
  is_secondary_section: false,
  is_mapped: false,
};

export const useMainSectionWithOthers = (state: SectionListViewState) => {
  let mainSections: Section[] = [];
  let othersSections: Section[] = [];

  for (var s of state.items) {
    if (s.is_main_section) {
      mainSections.unshift(s);
    } else if (SPECIAL_SECTIONS_NAMES.includes(s.name)) {
      mainSections.push(s);
    } else if (s.is_secondary_section) {
      mainSections.push(s);
    } else {
      othersSections.push(s);
    }
  }
  if (othersSections.length > 0) {
    mainSections.push(OTHER_EXHIBITION);
  }

  return { mainSections, othersSections };
};

const SectionListPage = () => {
  const i18n = useIntl();
  const { state, vm, platform } = useSectionListPageController();
  const otherRouteMatch = useRouteMatch(AppRoutes.SECTION_OTHERS);
  const { mainSections, othersSections } = useMainSectionWithOthers(state);
  const [otherSectionsSearchQuery, setOtherSectionsSearchQuery] = useState("");

  if (otherRouteMatch) {
    const filteredSections =
      otherSectionsSearchQuery.trim() !== ""
        ? othersSections.filter((o) => o.name.toLowerCase().includes(otherSectionsSearchQuery.toLowerCase()))
        : othersSections;

    return (
      <Header backAction={() => getNavigationService().value().goToHome()}>
        <div className="SectionListPage-root">
          <div className="SectionListPage-title">
            <FormattedMessage id="SectionListPage_OtherExpo" />
          </div>
          <div className="SectionListPage-actions">
            <CustomSearch
              inputValue={otherSectionsSearchQuery}
              placeholder={i18n.formatMessage({ id: "COMMON_SEARCH" })}
              onChange={(value) => setOtherSectionsSearchQuery(value)}
            />
          </div>
          <OtherSectionList items={filteredSections} />
        </div>
      </Header>
    );
  }

  const getPlatform = () => {
    if (platform === "Android" || platform.startsWith("Linux")) {
      return "Android";
    } else if (platform === "iPhone" || platform === "iPod" || platform === "iPad") {
      return "iOS";
    } else {
      return "other";
    }
  };

  return (
    <div className="SectionListPage-root">
      {
        <CustomDialog
          open={state.showDialog}
          onClose={vm.onCloseDialog}
          renderActions={() => ""}
          classes={{ paper: "SectionListPage-Dialog-paper" }}>
          <div className="SectionListPage-dialogContent-root">
            <div className="SectionListPage-dialogContent-helpText">
              <FormattedMessage id="DownloadApp_Dialog_helpText" />
            </div>
            <a
              className="SectionListPage-dialogContent-downloadLink"
              href={
                getPlatform() === "Android"
                  ? "https://play.google.com/store/apps/details?id=fr.conditionpublique.app"
                  : "https://apps.apple.com/fr/app/la-condition-publique/id1591062113"
              }>
              {(getPlatform() === "Android" || getPlatform() === "other") && (
                <img src={DownloadAndroid} alt="download android" />
              )}
              {(getPlatform() === "iOS" || getPlatform() === "other") && <img src={DownloadIos} alt="download ios" />}
            </a>
          </div>
        </CustomDialog>
      }
      {!state.showDialog && <NewsDialog />}
      <div className="SectionListPage-header">
        <img src={ImgConditionPublique} alt="logo" className="SectionListPage-logo" />
      </div>
      <SectionList items={mainSections} />
    </div>
  );
};

export { SectionListPage };
