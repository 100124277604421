import { useIntl } from "react-intl";
import { SectionListViewModel, SectionListViewState } from "../../../Core/ViewModels/Section/SectionListViewModel";
import { ViewModelLocator } from "../../../Core/ViewModels/ViewModelLocator";
import { useLifecycle } from "../../Hooks/Lifecycle/useLifecycle";

export const useSectionListPageController = () => {
  const platform = window.navigator.platform;

  const { vm, state } = useLifecycle<SectionListViewState, SectionListViewModel>({
    getRef: () => ViewModelLocator.SectionListVM,
    setRefValue: (value) => (ViewModelLocator.SectionListVM = value),
    newInstance: () => new SectionListViewModel(),
    logName: "SectionListVM",
  });

  return { vm, state, platform };
};
